import { LoadingOverlay, MantineProvider } from "@mantine/core";
import "@mantine/core/styles.css";
import React from "react";
import { Toaster } from "react-hot-toast";
import { AppProvider } from "./contexts/AppContext";
import { ModalsProvider } from "@mantine/modals";
import { UserProvider } from "./contexts/UserContext";
import { RouteConfigs } from "./routes/RouteConfigs";
import { theme } from "./theme";
import { KeyCloakProvider, useKeyCloak } from "./contexts/KeyCloakContext";
import "@mantine/dates/styles.css";

function App() {
  return (
    <React.Suspense fallback={<LoadingOverlay visible={true} />}>
      <MantineProvider
        theme={theme}
        withCssVariables={true}
        withGlobalClasses={true}
        defaultColorScheme="light"
      >
        <Toaster />
        <ModalsProvider>
          <AppProvider>
            <KeyCloakProvider>
              <UserProvider>
                <AppChildren />
              </UserProvider>
            </KeyCloakProvider>
          </AppProvider>
        </ModalsProvider>
      </MantineProvider>
    </React.Suspense>
  );
}

function AppChildren() {
  const { isAuthenticated } = useKeyCloak();

  return isAuthenticated!() ? (
    <RouteConfigs />
  ) : (
    <React.Suspense fallback={<LoadingOverlay visible={true} />} />
  );
}

export default App;

export const DEFAULT_CHAR_LIMIT = 20;

/**
 * Truncate text to a certain limit
 * @param text
 * @param limit
 * @returns
 */
export const truncateText = (
  text: string,
  limit: number = DEFAULT_CHAR_LIMIT
) => {
  return text.length > limit ? text.slice(0, limit) + "..." : text;
};

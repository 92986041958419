import {
  Center,
  Tooltip,
  UnstyledButton,
  Stack,
  rem,
  Badge,
  Avatar,
  Text,
} from "@mantine/core";
import {
  IconBrandChrome,
  IconCheck,
  IconHome2,
  IconInfoCircle,
  IconLayoutSidebarLeftExpand,
  IconLogout,
} from "@tabler/icons-react";
import IV_LOGO from "../../assets/logo.png";
import classes from "./navbarCollapsed.module.css";
import { NavbarProps } from "./NavbarApp";
import { isInRole } from "../../utils/userRole.util";
import { useUser } from "../../contexts/UserContext";
import { truncateText } from "../../utils/text.util";

interface NavbarLinkProps {
  icon: typeof IconHome2;
  label: string;
  active?: boolean;
  onClick?(): void;
}

function NavbarLink({ icon: Icon, label, active, onClick }: NavbarLinkProps) {
  return (
    <Tooltip label={label} position="right" transitionProps={{ duration: 0 }}>
      <UnstyledButton
        onClick={onClick}
        className={classes.link}
        data-active={active || undefined}
      >
        <Icon style={{ width: rem(20), height: rem(20) }} stroke={1.5} />
      </UnstyledButton>
    </Tooltip>
  );
}

export function NavbarCollapsed({
  data,
  expanded,
  setExpanded,
  navigate,
  logoutCurrentUser,
  getCurrentUserRoles,
  active,
  setActive,
  extensionStatus,
  handleExtensionDownload,
}: NavbarProps) {
  const roles = getCurrentUserRoles!();
  const { currentUserProfile } = useUser();

  const links = data?.map((item) =>
    isInRole(item?.roles!, roles) ? (
      <NavbarLink
        icon={item.icon}
        label={item.label}
        active={item.label === active}
        onClick={() => {
          if (item?.disabled === false) {
            setActive(item.label);
            navigate(item.link);
          }
        }}
        key={item.label}
      />
    ) : null
  );

  return (
    <nav className={classes.navbar}>
      <Center>
        <img
          src={IV_LOGO}
          alt="IV Logo"
          width={45}
          height={45}
          style={{
            marginTop: rem(20),
            marginBottom: rem(20),
          }}
        />
      </Center>

      <div className={classes.navbarMain}>
        <Stack justify="center" gap={10}>
          <NavbarLink
            icon={IconLayoutSidebarLeftExpand}
            label="Expand"
            onClick={() => setExpanded(!expanded)}
          />
          {links}
        </Stack>
      </div>

      <Stack justify="center" gap={0}>
        <Tooltip
          label={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: 10,
              }}
            >
              <Text style={{ fontSize: 14, fontWeight: 700 }}>
                {truncateText(
                  `${currentUserProfile?.firstName ?? ""} ${
                    currentUserProfile?.lastName ?? ""
                  }`
                )}
              </Text>
              <Text style={{ fontSize: 14 }}>
                {truncateText(`${currentUserProfile?.companyName ?? "-"}`)}
              </Text>
            </div>
          }
          position="right"
          transitionProps={{ duration: 0 }}
        >
          <Avatar
            size="md"
            variant="white"
            style={{ marginLeft: 5 }}
            onClick={() => {
              navigate("/settings");
            }}
          >
            {currentUserProfile?.firstName?.charAt(0).trim() || "-"}
          </Avatar>
        </Tooltip>

        <Tooltip
          label={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: 10,
                alignItems: "center",
              }}
            >
              Chrome Extension{" "}
              {extensionStatus === "installed" ? (
                <Badge
                  color="green"
                  style={{ marginTop: rem(5) }}
                  leftSection={
                    <IconCheck
                      color="white"
                      style={{ width: rem(15), height: rem(15) }}
                    />
                  }
                >
                  Installed
                </Badge>
              ) : (
                <Badge
                  color="red"
                  style={{ marginTop: rem(5) }}
                  leftSection={
                    <IconInfoCircle
                      color="white"
                      style={{ width: rem(15), height: rem(15) }}
                    />
                  }
                >
                  Not Installed
                </Badge>
              )}
            </div>
          }
          position="right"
          transitionProps={{ duration: 0 }}
        >
          <a
            href="#"
            className={classes.link}
            onClick={(event) => {
              event.preventDefault();
            }}
            style={{ marginTop: 10 }}
          >
            {extensionStatus === "installed" ? (
              <IconBrandChrome color="lightgreen" />
            ) : (
              <IconBrandChrome
                color="orange"
                onClick={() => {
                  handleExtensionDownload!();
                }}
              />
            )}
          </a>
        </Tooltip>

        <NavbarLink
          icon={IconLogout}
          label="Logout"
          onClick={logoutCurrentUser}
        />
      </Stack>
    </nav>
  );
}

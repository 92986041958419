import { LoadingOverlay } from "@mantine/core";
import Keycloak from "keycloak-js";
import kc from "./KeyCloak"
import React from "react";
import { IV_TOKEN } from "../constants/storage.constants";

interface IKeyCloakContextProps {
  logout: () => void;
  login: () => void;
  register: () => void;
  isAuthenticated?: () => boolean;
  keycloak?: Keycloak.KeycloakInstance;
  getRealmRoles?: () => string[];
}

interface IKeyCloakState {
  isLoaded: boolean;
}

type KeyCloakStateActions = { type: "loaded"; isLoaded: boolean };

const KeyCloakContextStateReducer = (
  state: IKeyCloakState,
  action: KeyCloakStateActions
) => {
  switch (action.type) {
    case "loaded":
      return { ...state, isLoaded: action.isLoaded };
    default:
      return state;
  }
};

function formatDate(timestamp: number): string {
  return new Date(timestamp * 1000).toISOString(); // Converts Unix timestamp to ISO string
}

export const KeyCloakContext = React.createContext<Partial<IKeyCloakContextProps>>({});

function logWithDate(message: string, ...optionalParams: any[]) {
  const now = new Date();
  const timestamp = now.toISOString(); // You can format this as needed
  console.log(`[${timestamp}] ${message}`, ...optionalParams);
}

export const KeyCloakProvider = ({ children } : { children: any }) => {
  const [state, dispatch] = React.useReducer(KeyCloakContextStateReducer, {
    isLoaded: false,
  } as IKeyCloakState);
  
  const [keycloakInstance, setKeycloakInstance] = React.useState<Keycloak.KeycloakInstance | undefined>(undefined);
  const kcInitialized = React.useRef(false);

  React.useEffect(() => {
    if (kcInitialized.current) return;
    const initializeKeycloak = async () => {
      try {
        const authenticated = await kc.init({ onLoad: "login-required", checkLoginIframe: false });
        if (authenticated) {
          setKeycloakInstance(kc);
          localStorage.setItem(IV_TOKEN, kc.token || "");
          // logWithDate('User authenticated');
        } else {
          // logWithDate('User not authenticated');
        }
        dispatch({ type: "loaded", isLoaded: true });

        kc.onAuthRefreshSuccess = () => {
          // logWithDate('Auth refresh success');
          localStorage.setItem(IV_TOKEN, kc.token || "");
        };

        kc.onAuthRefreshError = () => {
          // logWithDate("Auth refresh error: Invalid session or client configuration.");
          kc.logout({ redirectUri: window.location.origin });
        };

        const checkTokenExpiration = () => {
          if (kc.isTokenExpired(60)) {
            // logWithDate('Token is expired or will expire in 60 seconds');
            kc.updateToken(60).then(refreshed => {
              if (refreshed) {
                const exp = kc.tokenParsed?.exp;
                if (exp) { // Ensure exp is defined
                  const expiryDate = formatDate(exp);
                  // logWithDate('Token refreshed', `Expires at: ${expiryDate}`);
                } else {
                  // logWithDate('Token refreshed but expiration date not available');
                }
                localStorage.setItem(IV_TOKEN, kc.token || "");
              } else {
                // logWithDate('Token is still valid');
              }
            }).catch(error => {
              // logWithDate('Failed to refresh token', error);
            });
          } else {
            // logWithDate('Token is valid');
          }
        };
        const refreshInterval = 30000; // 30 seconds
        const intervalId = setInterval(checkTokenExpiration, refreshInterval);
        return () => clearInterval(intervalId);

      } catch (error) {
        console.error("Keycloak init failed", error);
        dispatch({ type: "loaded", isLoaded: true });
      }
    };
    initializeKeycloak();
    kcInitialized.current = true;
  }, []);

  if (!state.isLoaded || keycloakInstance === undefined) {
    return <LoadingOverlay visible={true} />;
  }

  return (
    <KeyCloakContext.Provider
      value={{
        logout: () => keycloakInstance?.logout({ redirectUri: window.location.origin }),
        login: () => keycloakInstance?.login(),
        register: () => keycloakInstance?.register(),
        isAuthenticated: () => keycloakInstance?.authenticated || false,
        keycloak: keycloakInstance,
        getRealmRoles: () => keycloakInstance?.tokenParsed?.realm_access?.roles || [],
      }}
    >
      {children}
    </KeyCloakContext.Provider>
  );
};

export function useKeyCloak() {
  const context = React.useContext(KeyCloakContext);
  if (context === undefined) {
    throw new Error("useKeyCloak must be used inside KeyCloakProvider");
  }
  return context;
}

import { LoadingOverlay } from "@mantine/core";
import React from "react";
import { IAgency } from "../types/agency.types";
import { IAgent } from "../types/agent.types";
import { IV_TOKEN, IV_USER } from "../constants/storage.constants";
import { useKeyCloak } from "./KeyCloakContext";
import profileService from "../services/profile.service";

type IUser = IAgent & IAgency;

interface IUserContextProps {
  currentUserProfile: IUser;
  setCurrentUserProfile: React.Dispatch<React.SetStateAction<Partial<IUser>>>;
  getUserId: () => string | undefined;
  isAuthenticated: () => boolean;
  logoutCurrentUser: () => void;
  getCurrentUserRoles: () => string[];
  getCurrentRole: () => string[];
}

interface IUserContextState {
  isLoaded: boolean;
  currentUserProfile: Partial<IUser>;
}

type UserContextStateActions =
  | { type: "current-user"; currentUserProfile: Partial<IUser> }
  | { type: "loaded"; isLoaded: boolean };

const UserContextStateReducer = (
  state: IUserContextState,
  action: UserContextStateActions
) => {
  switch (action.type) {
    case "loaded":
      return { ...state, isLoaded: action.isLoaded };

    case "current-user":
      return { ...state, currentUserProfile: action.currentUserProfile };

    default:
      return state;
  }
};

export const UserContext = React.createContext<Partial<IUserContextProps>>({});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const UserProvider = React.memo((props: any) => {
  const [state, dispatch] = React.useReducer(UserContextStateReducer, {
    currentUserProfile: {},
    isLoaded: false,
  } as IUserContextState);
  const { keycloak, logout } = useKeyCloak();

  const logoutCurrentUser = () => {
    dispatch({
      type: "current-user",
      currentUserProfile: {},
    });
    localStorage.removeItem(IV_USER);
    localStorage.removeItem(IV_TOKEN);
    logout!();
  };

  const filterRoles = (roles: string[]) => {
    return roles.filter(
      (role) =>
        role === "admin" ||
        role === "agency" ||
        role === "agent" ||
        role === "applicant" ||
        role === "reviewer" ||
        role === "distributor"
    );
  };

  React.useEffect(() => {
    const token = localStorage.getItem(IV_TOKEN);
    const storedUserProfile = localStorage.getItem(IV_USER);

    if (!token || !keycloak?.authenticated || !keycloak?.tokenParsed) {
      logoutCurrentUser();
      return;
    }

    if (storedUserProfile) {
      const currentUserProfile = JSON.parse(storedUserProfile);
      dispatch({
        type: "current-user",
        currentUserProfile,
      });
      dispatch({ type: "loaded", isLoaded: true });
      return;
    }

    const user = keycloak?.tokenParsed;
    const roles = filterRoles(user?.realm_access?.roles!);
    const userData = {
      id: user?.sub,
      firstName: user?.given_name,
      lastName: user?.family_name,
      email: user?.email,
      role: roles || "applicant",
    };

    if (userData?.id && userData?.role) {
      profileService
        .profile(userData.id, userData.role[0])
        .then((res) => {
          const currentUserProfile = res ? { ...res, ...userData } : userData;
          dispatch({
            type: "current-user",
            currentUserProfile,
          });
          localStorage.setItem(IV_USER, JSON.stringify(currentUserProfile));
        })
        .catch(() => {
          dispatch({
            type: "current-user",
            currentUserProfile: userData,
          });
          localStorage.setItem(IV_USER, JSON.stringify(userData));
        });
      dispatch({ type: "loaded", isLoaded: true });
    }
  }, [keycloak?.tokenParsed]);

  React.useEffect(() => {
    dispatch({ type: "loaded", isLoaded: true });
  }, []);

  if (!state.isLoaded) {
    return <LoadingOverlay visible={true} />;
  }

  return (
    <UserContext.Provider
      value={{
        currentUserProfile: state?.currentUserProfile,
        getUserId: () => state?.currentUserProfile?.id,
        setCurrentUserProfile: (currentUserProfile: Partial<IUser>) => {
          dispatch({ type: "current-user", currentUserProfile });
        },
        getCurrentRole: () =>
          state?.currentUserProfile?.role?.filter(
            (role) =>
              role === "admin" ||
              role === "agency" ||
              role === "agent" ||
              role === "applicant" ||
              role === "reviewer" ||
              role === "distributor"
          ),
        getCurrentUserRoles: () => state?.currentUserProfile?.role,
        isAuthenticated: () => state?.currentUserProfile?.id !== undefined,
        logoutCurrentUser,
      }}
      {...props}
    />
  );
});

// eslint-disable-next-line react-refresh/only-export-components
export function useUser() {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used inside UserProvider");
  } else {
    return context;
  }
}

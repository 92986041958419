import { IAgency } from "../types/agency.types";
import { IAgent } from "../types/agent.types";
import apiUtil from "../utils/api.util";

class profileService {
  route = "/user";

  profile = async (id: string, role: string): Promise<IAgency | IAgent> => {
    const response = await apiUtil.get(`${this.route}/get-profile`, {
      id,
      role,
    });

    return response.response;
  };
}

export default new profileService();

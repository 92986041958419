import { Button, Container, Text, Title } from "@mantine/core";
import classes from "./fourZeroFour.module.css";

export function FourZeroFour() {
  return (
    <div className={classes.root}>
      <Container>
        <div className={classes.label}>!</div>
        <Title className={classes.title}>You have found a secret place!</Title>
        <Text size="lg" ta="center" className={classes.description}>
          The page you are looking for does not exist. Please check the URL or
          click on the link to navigate to the home page.
        </Text>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="white"
            onClick={() => {
              window.location.href = "/";
            }}
          >
            Go to home page
          </Button>
        </div>
      </Container>
    </div>
  );
}

import { LoadingOverlay } from "@mantine/core";
import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import { getWebRoutes } from "./RouteImports";
import { NavbarApp } from "../components/navbarApp/NavbarApp";
import { IRouteObject } from "../types/route.types";
import { useUser } from "../contexts/UserContext";
import { FourZeroFour } from "../components/fourZeroFour/FourZeroFour";
import { isInRole } from "../utils/userRole.util";

interface ProtectedRouteProps extends IRouteObject {
  userRoles: string[];
}

/**
 * ProtectedRoute component to handle role-based route protection.
 */
const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  roles,
  userRoles,
}) => {
  return isInRole(roles || [], userRoles) ? <Component /> : <FourZeroFour />;
};

const WebRoutes = ({ userRoles }: { userRoles: string[] }) => {
  return (
    <Routes>
      {getWebRoutes()?.map((routes, i) => (
        <Route
          key={`RouteWeb-${i}`}
          path={routes.path}
          element={
            <ProtectedRoute
              component={routes.component}
              roles={routes.roles || []}
              userRoles={userRoles || []}
              path={routes.path}
              exact={routes.exact}
            />
          }
        />
      ))}
    </Routes>
  );
};

const SuspenseFallback = () => <LoadingOverlay visible={true} />;

const RouteChildWrapper = () => {
  const { getCurrentUserRoles } = useUser();
  const userRoles = getCurrentUserRoles ? getCurrentUserRoles() : [];

  return (
    <NavbarApp>
      <React.Suspense fallback={<SuspenseFallback />}>
        <WebRoutes userRoles={userRoles} />
      </React.Suspense>
    </NavbarApp>
  );
};

export const RouteConfigs = () => {
  return (
    <HashRouter>
      <RouteChildWrapper />
    </HashRouter>
  );
};

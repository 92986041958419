export const checkExtensionStatus = () => {
  return new Promise((resolve, reject) => {
    window.postMessage(
      {
        action: "Extension",
      },
      "*"
    );

    const messageListener = (event: any) => {
      if (event.data.action === "upAndRunning") {
        console.log("extension up and running");
        resolve(true);
        window.removeEventListener("message", messageListener);
      }
    };

    window.addEventListener("message", messageListener);

    setTimeout(() => {
      window.removeEventListener("message", messageListener);
      resolve(false);
    }, 2000);
  });
};

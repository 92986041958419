import React from "react";
import { useMediaQuery } from "react-responsive";

type Devices = "Mobile" | "Desktop" | "Tablet" | "Default";

export interface IAppContextProps {
  /**
   * Contains device type using media query @see https://github.com/yocontra/react-responsive
   */
  deviceType: Devices | undefined;
}

interface IAppContextState {
  deviceType: Devices | undefined;
}

type IAppContextStateActions = {
  type: "device-type";
  deviceType: Devices | undefined;
};

const AppContextStateReducer: React.Reducer<
  IAppContextState,
  IAppContextStateActions
> = (state, action) => {
  switch (action.type) {
    case "device-type":
      return { ...state, deviceType: action.deviceType };
  }
};

const AppContext = React.createContext<Partial<IAppContextProps>>({});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AppProvider = React.memo((props: any) => {
  const AppState: IAppContextState = {
    deviceType: undefined,
  };

  const [state, dispatch] = React.useReducer(AppContextStateReducer, AppState);
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const isTablet = useMediaQuery({ minWidth: 700, maxWidth: 800 });
  const isDefault = useMediaQuery({ minWidth: 768 });

  React.useEffect(() => {
    if (isMobile) {
      dispatch({ type: "device-type", deviceType: "Mobile" });
    } else if (isTablet) {
      dispatch({ type: "device-type", deviceType: "Tablet" });
    } else if (isDefault) {
      dispatch({ type: "device-type", deviceType: "Desktop" });
    } else {
      dispatch({ type: "device-type", deviceType: "Default" });
    }
  }, [isDefault, isTablet, isMobile]);

  return (
    <AppContext.Provider
      value={{
        deviceType: state.deviceType,
      }}
      {...props}
    />
  );
});

// eslint-disable-next-line react-refresh/only-export-components
export const useApp = () => {
  const context = React.useContext(AppContext);
  if (context === undefined) {
    throw new Error("useApp() must be used inside AppProvider");
  } else {
    return context;
  }
};

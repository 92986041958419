export const SUPPORTED_DOCS = [
  "Aadhar Card",
  "Passport",
  "Pancard",
  "Electricity Bill",
  "Tax Challan",
  "Invoices",
  "Bill Of Entry",
  "Certificate of incorporation",
  "Proof Of funds",
  "Hotel Bill",
  "Food Bill",
  "Travel Bill",
  "Ration Card",
  "Income Application Form",
  "EPIC Card",
  "Study Certificate",
  "Study Exam Hall Ticket",
  "Birth Certificate",
  "Bonafide Certificate",
  "DOB & Community Application Form",
];

export const ROLES = {
  ADMIN: "admin",
  AGENCY: "agency",
  AGENT: "agent",
  APPLICANT: "applicant",
  REVIEWER: "reviewer",
  DISTRIBUTOR: "distributor",
};
